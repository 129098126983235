import React from "react";
import "./home.css";

export default function Expert() {
  return (
    <>
      <section className=" studentsection mt-20  ">
        <div className="grid grid-cols-1   lg:mx-32 sm:mx-16 mx-8  ">
          <div className="p-3 text-center  heading1 ">
            <h3 className="bx2  sm:text-2xl text-xl uppercase font-semibold  text-white mt-40 md:mt-24">
              What Our Students Says ?
            </h3>
          </div>
        </div>
        <div className="lg:border-2 border-red-500 grid grid-cols-1 mx-[600px] justify-center animate-bounce ">
          <div className=" absolute hideen lg:block"></div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mx-12 lg:mx-32  mt-12  gap-10 sm:gap-20">
          <div className=" flex justify-center bx2">
            <iframe
              width="200"
              height="350"
              src="https://www.youtube.com/embed/wYbF_iUE4yg"
              title="Students Reaction Part 2 | Netligent Technologies LLP | IT Training &amp; Placement Institutes"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <div className=" flex justify-center  bx2">
            <iframe
              width="200"
              height="350"
              src="https://www.youtube.com/embed/dyzVPoebLzY"
              title="Students Reaction About Netligent | Netligent Technologies LLP | IT Training &amp; Placement Institutes"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>

          <div className="justify-center  flex bx2">
            <iframe
              width="200"
              height="350"
              src="https://www.youtube.com/embed/lc8jxJjpM0Y"
              title="Feedback From Our Happy Students | Netligent Technologies #ittraining #itcourse"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div className="justify-center  flex bx2">
            <iframe
              width="200"
              height="350"
              src="https://www.youtube.com/embed/ZUXKM1vHjPE"
              title="Inspiring Story Of Our Student Vipul Soni | Netligent Technologies  LLP #ittraining #itcourse"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mt-12 mx-12 lg:mx-32  mb-24 gap-10 sm:gap-20 self-center">
          <div className=" flex justify-center md:justify-end  md:mb-32 bx3">
            <iframe
              width="200"
              height="350"
              src="https://www.youtube.com/embed/KOIaVRA79wg"
              title="Story Of Our Happy Student | Netligent Technologies #ittraining #itcourse #aws #cloudcomputing"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>

          <div className=" flex justify-center md:justify-start mb-44 md:mb-32 bx2">
            <iframe
              width="200"
              height="350"
              src="https://www.youtube.com/embed/WNc4OuhUtqc"
              title="Another Bunch OF Happy Faces | Students Feedback | Netligent Technologies"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
      {/* Expert  section end  */}
    </>
  );
}
