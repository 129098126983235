import React from "react";
import ServerAssociate from "./Server_Associate";

export default function SerevrAssociateLink() {
  return (
    <div>
      <ServerAssociate />
    </div>
  );
}
