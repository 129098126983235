import React from "react";
import Gal from "./gallery";
import MetaData from "../../../Layout/Layout";
export default function GalleryLink() {
    return (
      <div>
        <MetaData title="Gallery - Netligent Technologies " ></MetaData>
        <Gal/>
      </div>
    )
  }