import React from "react";
import CyberSecurityProfessional from "./Cs_professional";

export default function CsProfessionalLink() {
  return (
    <div>
      <CyberSecurityProfessional />
    </div>
  );
}
