import React from 'react';
export default function AboutFifth() {
    return (
        <>

            {/* Fifith Section(Mission, Vision, Goal)  of About Us*/}
            <section className='bg-[#F2F4F8]'>
                <div className='grid grid-cols-1 lg:grid-cols-3 mx-8 sm:mx-24 md:mx-48  lg:mx-24 gap-0 md:gap-5 lg:gap-14 ' >
                    <div className='  font-sanf mt-56 box1 rounded-lg border border-white bx2'>
                        <div className=' -mt-36  justify-center flex '>
                            <img src='man1.png' className='h-52  z-20' alt=''/>
                        </div>
                        <div className='p-5  block justify-center text-center mb-4'>
                            <h1 className=' uppercase text-2xl text-[#1A2942]'>mission</h1>
                            <p className='mt-5 text-gray-700'>At Netligent Technologies, our unwavering commitment is to excel in IT Training
                                and empower the younger generation, eliminating any barriers that may hinder them from embarking
                                on a rewarding career in the IT industry. We are dedicated to continuously motivating and offering
                                comprehensive services to our youth, enabling them to take confident strides forward and realize
                                their aspirations.</p>
                        </div>
                    </div>


                    <div className=' bx3 font-sanf justify-center block mt-40 lg:mt-56 box1 rounded-lg border border-white'>
                        <div className=' -mt-32 justify-center flex  z-20 '>
                            <img src='man2.png' className='h-52  z-50 ' alt=''/>
                        </div>
                        <div className='p-5  block justify-center text-center mb-4'>
                            <h1 className=' uppercase text-2xl text-[#1A2942]'>vision</h1>
                            <p className='mt-5 text-gray-700'>Our vision is not just about delivering courses it's about transforming 
                            lives and opening doors to a world of endless possibilities. We are passionate about building a brighter
                             tomorrow through IT education and placement services. Join us on this journey of transformation, where your 
                             success becomes our success. Together, we'll create a future where IT excellence knows no bounds.</p>
                        </div>
                    </div>

                    <div className=' bx2 font-sanf mt-44 lg:mt-56 box1 rounded-lg border border-white'>
                        <div className=' -mt-36  justify-center flex '>
                            <img src='man3.png' className='h-52 ' alt=''/>
                                                    </div>
                        <div className='p-5 block justify-center text-center mb-4'>
                            <h1 className=' uppercase text-2xl text-[#1A2942]'>goal</h1>
                            <p className='mt-5 text-gray-700'>There are millions of peoples are not aware about the latest technologies
                                that surrounds their life. So, Our main goal is to provide students who are eager to get into the IT Industry
                                a right platform to get their career in right direction. Basically, we focus on the IT Training and Placement,
                                IT Courses , our main priority is to search for new domain and present it in front of you to learn something
                                new.</p>
                        </div>
                    </div>
                </div>
            </section>












        </>
    )
}


