import React from "react";
import PythonFullstack from "./Py_Fullsatck";

export default function PyFullstackLink() {
  return (
    <div>
      <PythonFullstack />
    </div>
  );
}
