import React from "react";

export default function AboutFirst() {
  return (
    <>
      {/* First Section(about netligent) Of About Us */}
      <section className="">
        <div className="grid  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ">
          <div className=" text-start p-6 md:p-12 lg:p-16  lg:order-1 order-2 back  ">
            <h1 className="text-2xl  md:text-3xl lg:text-4xl font-bold  text-[#1A2942]  mt-10 lg:mt-0 scaleUp">
              The Trailblazers of the Future:
            </h1>
            <h1 className="text-[#c43238] text-2xl scaleUp  md:text-3xl lg:text-4xl font-bold">
              {" "}
              Netligent Technologies
            </h1>
            <p className="mt-5 slide-up text-black ">
              Hello, Dear friends, Welcome to{" "}
              <span className="text-[#1A2942] font-bold">
                Netligent Technologies LLP
              </span>
              , also we are happy you want to know something more about our
              organization. So, basically, nowadays people are more dependent on
              online products and services that's why we also, take forward a
              step to help online community by extending our expertise in the{" "}
              <span className="text-[#1A2942] font-bold">
                Field of IT Industry
              </span>
              . Our first wish is to provide you with a
              <span className="text-[#1A2942] font-bold">
                {" "}
                Better Training Experience
              </span>{" "}
              in our modern infrastructre. Also, we are trying to provide fresh
              & latest content that keeps you updated with the{" "}
              <span className="text-[#1A2942] font-bold">
                {" "}
                Information Technology
              </span>{" "}
              trends. If you have additional questions or require more
              information about us, do not hesitate to contact us through email
              at{" "}
              <span className="text-[#1A2942]  font-bold">
                netligenttechnologies@gmail.com
              </span>
            </p>
          </div>

          <div className=" items-center flex justify-center lg:order-2 order-1   ">
            <img
              src="a1.JPG"
              className=" h-full w-full img4"
              alt="loading..."
            />
          </div>
        </div>
      </section>
    </>
  );
}
