import React from "react";
export default function AboutSec() {
  return (
    <>
      {/* Sec Section(Meet Our Team) Of About Us */}
      <section className="bg-[#F2F4F8]   ">
        <div className="grid grid-cols-1  lg:mx-32 sm:mx-16  overflow-hidden   ">
          <div className="p-3 text-center mt-10 lg:mt-20  ">
            <h3 className=" font-sanf  text-[#c43238]   text-2xl sm:text-4xl uppercase  font-bold bx1 ">
              Meet Our Team
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-1  -mt-4 lg:mx-64 sm:mx-16 mx-4  overflow-hidden">
          <div className="p-3 text-center mb-16 lg:mb-20  ">
            <h3 className=" font-sans text-lg  text-[#1A2942]  bx4   ">
              {" "}
              Guidance from industry-experienced IT professionals{" "}
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}
