import React from "react";
import MernStack from "./MERNStack";

export default function MERNStackLink() {
  return (
    <div>
      <MernStack />
    </div>
  );
}
