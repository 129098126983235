import React from 'react';

import First from './first';
import Sec from './sec';
import Fourth from './fourth'
import Fifth from './enroll'
import Services from './services';
import Testimonal from './Testimonal';
import Placement from './placement';
import Expert from './expert';
import Counceler from './counceler';
import Courses2 from './courses';
import MetaData from '../../Layout/Layout'
import Connect from './About/coonect';
import AboutSix from './About/aboutsix';

export default function Landing() {
  return (
   
    <div  >
     {/* <Mypopup/> */}
     <MetaData title=" Netligent Technologies - IT Training & Placement Institute " ></MetaData>
     
      <First/>
      <Sec/>
      <Fourth/> 
      <Courses2/>
      <Expert/>
      <Counceler/>
      <Services/>
      <Fifth/>
      <AboutSix/>
      <Testimonal/>
      <Placement/>
      <Connect/>
 
    </div>
  
  )
}
