import React from "react";
export default function ContactFirst() {
  return (
    <>
      {/* First Section Of Contact page */}
      <section className="bg-[#eeeeee]">
        <div className="grid  grid-cols-1 sm:grid-cols-2   font-sanf  bg-[#1A2942] overflow-hidden rounded-tr-xl md:rounded-bl-[50%] md:rounded-br-[50%]   ">
          <div className=" text-start p-8 md:p-12 lg:p-16  sm:order-1 order-2 mb-16 bx1 ">
            <spna className="text-2xl  md:text-3xl lg:text-4xl font-bold  text-white ">
              We Enhance
            </spna>{" "}
            <br></br>
            <span className="text-2xl  md:text-3xl lg:text-4xl font-bold text-white">
              {" "}
              Customer
            </span>
            <span className="text-[#c43238] text-2xl  md:text-3xl lg:text-4xl font-bold">
              {" "}
              Interactions
            </span>
            <p className="mt-5  text-white ">
              In this highly competitive environment where every industry
              expects more, we support you in improving your skill foundation,
              leading to a quantifiable influence on your professional path.
            </p>
          </div>

          <div className=" items-center flex justify-center sm:order-2 order-1 sm:mb-16 ">
            <img
              src="c20.png"
              className=" h-48 bx4 sm:h-56 md:h-64 lg:h-80  mt-10 sm:mt-auto"
              alt="loading..."
            />
          </div>
        </div>
      </section>
    </>
  );
}
