import React from "react";
import Redhat from "./Redhat";

export default function RedhatLink() {
  return (
    <div>
      <Redhat />
    </div>
  );
}
