import React from "react";
import { Link } from "react-router-dom";
import {
  UilFacebookF,
  UilYoutube,
  UilInstagram,
  UilLinkedin,
} from "@iconscout/react-unicons";

export default function Footer() {
  return (
    <>
      <footer className="bg-[#1A2942] mt-10 text-white ">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8 ">
          <div className="lg:flex lg:items-start lg:gap-8 ">
            <div className="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16 ">
              <div className="col-span-2 ">
                <div>
                  <h2 className="text-2xl font-bold text-white fade-in">
                    Request A Demo className!
                  </h2>

                  <p className="mt-4 text-white fade-in">
                    Send Your Messsage To Request A Demo className And Get
                    Experience After That Join Us
                  </p>
                </div>
              </div>

              <div className="col-span-2 lg:col-span-3 lg:flex lg:items-end">
                <form className="w-full">
                  <label for="UserEmail" className="sr-only">
                    {" "}
                  </label>

                  <div className="border border-white p-2  sm:flex sm:items-center sm:gap-4 ">
                    <input
                      type="text"
                      id="UserEmail"
                      placeholder="Write Your Message"
                      className="w-full fade-in border-none outline-none  sm:text-sm bg-[#1A2942]"
                    />

                    <button className="mt-3 fade-in w-full bg-[#c43238] px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-[#1A2942] hover:border-white hover:border sm:mt-0 sm:w-auto sm:shrink-0">
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>

              <div className="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p className=" font-semibold text-white  text-xl fade-in">
                  Services
                </p>

                <ul className="mt-6 space-y-4 text-sm fade-in ">
                  <li>
                    <a
                      href="#classNameroomTraining"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      classNameroom Training
                    </a>
                  </li>

                  <li>
                    <a
                      href="#InstructorLedOnline"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Instructor Led Online
                    </a>
                  </li>

                  <li>
                    <a
                      href="#CorporateTraining"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Corporate Training
                    </a>
                  </li>

                  <li>
                    <a
                      href="#1-on-1Training"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      1-on-1 Training
                    </a>
                  </li>

                  <li>
                    <a
                      href="#SelfpacedTraining"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Selfpaced Training
                    </a>
                  </li>

                  <li>
                    <a
                      href="#Campus"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Campus Training
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-span-2 sm:col-span-1 lg:text-start lg:ml-10 fade-in">
                <p className="font-semibold text-white text-xl">Company</p>

                <ul className="mt-6 space-y-4 text-sm fade-in">
                  <li>
                    <Link
                      to="/About"
                      target="_parent"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      About
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Courses
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Career"
                      target="_parent"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Career
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Gallery"
                      target="_parent"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Gallery
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Contact"
                      target="_parent"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p className="font-semibold text-white text-xl fade-in">
                  Cyber Security
                </p>

                <ul className="mt-6 space-y-4 text-sm fade-in">
                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className=" fade-in text-white transition hover:opacity-75 "
                      rel="referrer"
                    >
                      Networking
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white fade-in transition hover:opacity-75"
                    >
                      CCNA
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white fade-in transition hover:opacity-75"
                    >
                      Redhat
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white fade-in transition hover:opacity-75"
                    >
                      Ubuntu
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white  fade-intransition hover:opacity-75"
                    >
                      Windows Server
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white fade-in transition hover:opacity-75"
                    >
                      Ethical Hacking
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p className="font-semibold text-white text-xl fade-in">
                  Web Development
                </p>

                <ul className="mt-6 space-y-4 text-sm fade-in">
                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white  fade-intransition hover:opacity-75"
                    >
                      Python/Java
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white fade-in transition hover:opacity-75"
                    >
                      Django
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white fade-in transition hover:opacity-75"
                    >
                      Mysql
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white  fade-intransition hover:opacity-75"
                    >
                      HTML/CSS/BootStrap
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/Courses"
                      target="_parent"
                      className="text-white  fade-in transition hover:opacity-75"
                    >
                      JavaSript/React
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="col-span-2 sm:col-span-1 lg:text-start ">
                <p className="font-semibold text-white text-xl fade-in ">
                  Location
                </p>

                <ul className="mt-6 space-y-4 text-sm fade-in">
                  <li>
                    <Link
                      to="#"
                      className="text-white transition hover:opacity-75 fade-in"
                    >
                      Building 4, Krishak Nagar, Near <br></br> Karond Square,
                      Karond Bhopal M.P.
                    </Link>
                  </li>

                  <li>
                    <Link to=''
                     
                      className="font-semibold text-white text-xl fade-in"
                    >
                      Contact
                    </Link>
                  </li>

                  <li>
                    <Link to=''
                     
                      className="text-white transition hover:opacity-75 "
                    >
                      +91 8349530962 <br></br>
                      +91 8349530982
                    </Link>
                  </li>
                </ul>
              </div>

              <ul className="col-span-2 flex justify-center gap-6  fade-in lg:col-span-5 fade-in">
                <Link to="https://www.facebook.com/profile.php?id=61551170937539">
                  <li>
                    <UilFacebookF
                      className="   w-7 h-7 grid place-content-center    hover:text-[#c43238]
                        transition ease-in-out duration-200"
                    />
                  </li>{" "}
                </Link>

                <Link to="https://www.youtube.com/@Netligent">
                  <li>
                    {" "}
                    <UilYoutube
                      className="  w-8 h-8 grid place-content-center    hover:text-[#c43238]  
                        transition ease-in-out duration-200"
                    />
                  </li>{" "}
                </Link>

                <Link to="https://www.instagram.com/netligenttechnologies/">
                  <li>
                    <UilInstagram
                      className="  w-7 h-8 grid place-content-center    hover:text-[#c43238]  
                        transition ease-in-out duration-200"
                    />
                  </li>{" "}
                </Link>

                <Link to="https://www.linkedin.com/feed/">
                  <li>
                    <UilLinkedin
                      className="  w-7 h-8 grid place-content-center    hover:text-[#c43238]  
                        transition ease-in-out duration-200"
                    />
                  </li>{" "}
                </Link>
              </ul>
            </div>
          </div>

          <div className="mt-8 border-t border-gray-100 pt-8">
            <div className="sm:flex sm:justify-between">
              <p className="text-xs text-white fade-in">
                &copy; 2023. Netligent Technologies LLP. All rights reserved.
              </p>

              <ul className="mt-8 flex  fade-in flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end">
                <li>
                  <Link to=''
                    
                    className="text-white transition hover:opacity-75 fade-in"
                  >
                    Terms & Conditions
                  </Link>
                </li>

                <li>
                  <Link to=''
                    
                    className="text-white transition hover:opacity-75 fade-in"
                  >
                    Privacy Policy
                  </Link>
                </li>

                <li>
                  <Link to=''
                    
                    className="text-white transition hover:opacity-75 fade-in"
                  >
                    Cookies
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
