import React from "react";

export default function CyberSecurityAssociate() {
  return (
    <>
      {/* <MetaData title="Netligent - Courses " ></MetaData> */}
      {/* Cyber Security Asssociated Course */}
      <section className="bg-[#c43238] text-white ">
        <div className="grid grid-cols-1 md:grid-cols-2  font-sanf md:gap-16">
          <div className=" w-full md:w-[600px] lg:w-[800px] p-10 md:p-5 lg:p-10    ">
            <h1 className=" text-2xl  sm:text-3xl lg:text-4xl font-sanf font-semibold   text-start md:ml-6 lg:ml-12">
              Cyber Security Associate Certification Training
            </h1>
            <p className="mt-4  md:ml-6 lg:ml-12 text-start">
              Immerse yourself in Cyber Security. Enroll now to learn from an
              expert and master the essentials swiftly. Elevate your skills
              today!
            </p>

            <div className="overflow-hidden grid grid-cols-2 md:grid-cols-4 border border-t-yellow-300 border-r-0 border-l-0 border-b-0  md:mx-6 lg:mx-12 mt-5 md:mt-10 text-start font-sanf ">
              <div className=" p-1 md:p-0 border border-r-yellow-300  border-t-yellow-300 md:border-t-0 border-l-yellow-300 md:border-l-0 border-b-0 mt-5 md:mt-4 text-center md:text-start">
                <p className=" text-md  font-semibold">Course Duration</p>
                <p className="text-sm mt-1 font-sanf">5 Months</p>
              </div>

              <div className="  p-1 md:p-0  border border-r-yellow-300 border-t-yellow-300 md:border-t-0 border-l-0 border-b-0 mt-5 md:mt-4 block text-center md:text-start ">
                <p className=" text-md font-semibold md:ml-5">Live Project</p>
                <p className="text-sm mt-1 font-sanf md:ml-5">2 Project</p>
              </div>

              <div className="  p-1 md:p-0   border border-r-0  border-t-yellow-300 md:border-t-0 border-l-yellow-300 md:border-l-0 border-b-yellow-300 md:border-b-0 mt-0 md:mt-4 block  text-center md:text-start">
                <p className=" text-md font-semibold md:ml-5">
                  Training Format
                </p>
                <p className="text-sm mt-1 font-sanf md:ml-5">Offline/Online</p>
              </div>

              <div className="  p-1 md:p-0 border border-t-yellow-300 md:border-t-0 border-b-yellow-300 md:border-b-0  border-l-yellow-300 border-r-yellow-300 md:border-r-0 block mt-0 md:mt-4 text-center md:text-start">
                <p className=" text-md font-semibold md:ml-5">
                  Certification Pass
                </p>
                <p className="text-sm mt-1 font-sanf md:ml-5">Guranteed</p>
              </div>
            </div>
          </div>

          <div className=" hidden  ml-32 md:flex justify-center :p-5 ">
            <img
              src="/cs3.png "
              className="  h-72   mt-5 "
              alt="loading..."
            ></img>
          </div>
        </div>
        {/* Speciality section  */}
        <div className="text-start flex -mt-5  ">
          <h1 className="ml-[40px] sm:ml-[120px] md:ml-[45px] lg:ml-[90px] font-bold text-xl">
            Speciality
          </h1>
        </div>
        <div className="  md:ml-0 gap-0 md:gap-5 lg:gap-10 border border-t-yellow-300 mt-2  border-r-0 border-b-0 border-l-0 grid grid-cols-1 md:grid-cols-4 mx-10 sm:mx-[120px] md:mx-[45px] lg:mx-[90px] font-sanf ">
          <div className="border border-yellow-300 text-center md:text-start  p-3 block mt-7 rounded-lg mb-5">
            <p className="    font-bold text-2xl ml-3 -mt-2">100 %</p>
            <p className="text-base font-sanf  font-semibold ml-3">
              Placement Assistance
            </p>
          </div>

          <div className="border border-yellow-300 text-center md:text-start  p-3 block md:mt-7 rounded-lg mb-5">
            <p className="   font-bold text-2xl ml-3 -mt-2">4 +</p>
            <p className="text-base font-sanf  font-semibold ml-3">
              Batches Every Month
            </p>
          </div>

          <div className="border border-yellow-300 text-center md:text-start  p-3 block md:mt-7 rounded-lg mb-5">
            <p className="   font-bold text-2xl ml-3 -mt-2">20 +</p>
            <p className="text-base font-sanf  font-semibold ml-3">
              Latest Courses
            </p>
          </div>

          <div className="border border-yellow-300 text-center md:text-start  p-3 block md:mt-7 rounded-lg mb-5 ">
            <p className="   font-bold text-2xl ml-3 -mt-2">2 +</p>
            <p className="text-base font-sanf  font-semibold ml-3">
              Live Projects
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
