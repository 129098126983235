import React from "react";
import ContactFirst from "./contactfirst";
import Form from "./form";
import Map from "./map";
import ContactSec from "./contactsec";
import Counceler from "../counceler"
import MetaData from "../../../Layout/Layout";

export default function ContactLink() {
    return (
      <div>
        <MetaData title="Contact - Netligent Technologies " ></MetaData>
        <ContactFirst/>
        <ContactSec/>
        <Form/>
       <Counceler/>
        <Map/>
      </div>
    )
  }