import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion'; // optional
import Typewriter from "typewriter-effect";
import "./home.css";
import { Link } from 'react-router-dom';
import { Blob1 } from '../../components/icons';
const courses = [
  {
    name: 'DevOps Certification',
    quotation: 'Streamline operations with our DevOps Certification program.',
  },
  {
    name: 'Ethical Hacking & Cyber Security',
    quotation: 'Safeguard your digital world with our comprehnesive Cybersecurity training',
  },
  {
    name: 'Cloud Computing',
    quotation: 'Master cloud architecture with our hands-on Cloud Computing training.',
  },
  {
    name: 'RedHat Expert',
    quotation: 'Become a certified Linux professional with our RedHat Expert training',
  },
  {
    name: 'Data Science with Python',
    quotation: 'Dive into the world of Data Analysis, machine learning, and data visualization.',
  },
  {
    name: 'Python Full Stack Development',
    quotation: 'Become a versatile developer with our Python/Java Full Stack Development program.',
  },
  {
    name: 'React Developer',
    quotation: 'Become a front-end expert with our React Developer training.',
  },
  {
    name: 'MERN Stack Development',
    quotation: 'Master full-stack development with our MERN Stack Development training.',
  },
];


const HeroSection = () => {
  const [currentCourseLeft, setCurrentCourseLeft] = useState(0);
  const [currentCourseRight, setCurrentCourseRight] = useState(0);

  // Split the courses into left and right groups
  const leftCourses = courses.filter((course) =>
    ['Ethical Hacking & Cyber Security', 'Cloud Computing', 'RedHat Expert', 'DevOps Certification'].includes(course.name)
  );

  const rightCourses = courses.filter((course) =>
    ['Data Science with Python', 'Python Full Stack Development', 'React Developer', 'MERN Stack Development'].includes(course.name)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCourseLeft((prevCourse) => (prevCourse + 1) % leftCourses.length);
      setCurrentCourseRight((prevCourse) => (prevCourse + 1) % rightCourses.length);
    }, 3000); // Change course every 3 seconds

    return () => clearInterval(interval);
  }, [leftCourses.length, rightCourses.length]);

  const directionVariants = {
    top: { opacity: 0, y: -20 },
    bottom: { opacity: 0, y: 20 },
    left: { opacity: 0, x: -20 },
    right: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0, y: 0 },
  };
  const directionVariants2 = {
    bottom: { opacity: 0, y: -20 },
    top: { opacity: 0, y: 20 },
    right: { opacity: 0, x: -20 },
    left: { opacity: 0, x: 20 },
    visible: { opacity: 1, x: 0, y: 0 },
  };

  const getDirection = (index) => {
    const directions = ['top', 'bottom', 'right', 'left'];
    return directions[index % 4]; // Cycle through top, bottom, right, left
  };

  const getDirection2 = (index) => {
    const directions = ['top', 'bottom', 'right', 'left'];
    return directions[index % 4]; // Cycle through top, bottom, right, left
  };
  return (
    <section className="relative flex flex-col -mt-24 md:-mt-5 min-h-screen md:min-h-0  gap-7 items-center justify-center  md:pb-24   bg-[#F2F4F8] text-black overflow-hidden">
    <Blob1 className='absolute w-[500px] sm:w-[500px] md:w-[800px] z-10 -translate-x-[50%] translate-y-[60%] sm:translate-y-[50%] md:translate-y-[20%] lg:translate-y-[15%] -left-[20%] sm:-left-[10%] md:-left-[20%] lg:-left-[5%] '/>
    <Blob1 className='absolute w-[400px] sm:w-[500px] md:w-[800px] z-10 translate-x-[50%] -translate-y-[60%] sm:-translate-y-[40%] md:-translate-y-[15%] -right-[15%] sm:-right-[5%] md:-right-[15%] lg:-right-[5%] '/>
    <img src='dot.png' alt='' className='absolute  rotate-45 -left-20 top-40 sm:left-10 sm:top-24 '/>
    <img src='dot.png' alt='' className='absolute rotate-45 -right-20 bottom-40  md:right-10 md:bottom-24'/>
    
      <p className="font-bold relative z-40 text-[35px] md:text-[50px] xl:text-[64px] z-60 w-[80%] md:w-[60%] lg:w-[50%] leading-[1.2] md:leading-[55px] xl:w-[100%] -mt-0 md:mt-24">
                  <Typewriter
                    options={{
                      autoStart: true,
                      delay: 120,
                      loop: true,
                      strings: [
                        "Unlock Your Tech Potential",
                      ],
                    }}
                  />
                </p>
                <div className='flex relative z-20 justify-center items-center text-center leading-[1.2] md:leading-normal text-xl md:text-2xl font-[500] md:font-semibold -mt-5 md:-mt-0 xl:-mt-2  w-[80%] md:w-[50%] xl:w-[100%]'>
      Your Gateway to a Successful Tech Career Begins Here
      </div>
      <Link to='/Courses'>
      <button className="flex justify-center items-center px-4 md:px-8 py-2 md:py-3 bg-black text-[16px] md:text-lg hover:bg-red-600 text-white font-semibold rounded-lg transition duration-300"
       style={{
        boxShadow:'0 0 4px 4px rgba(0,0,0,0.10)'
      }}>
          Explore Our Courses
        </button>
      </Link>
      <div className="  flex flex-col sm:flex-row justify-center gap-5 items-center  w-full  sm:w-[90%] md:w-[78%] lg:w-[75%] xl:w-[75%]  md:mt-14 lg:mt-8 ">
        <div className='relative z-20 flex justify-start items-start  w-[100%]  md:w-[45%] xl:w-[50%] pl-4 sm:pl-0 '>
        <motion.div
              key={currentCourseLeft}
              initial={directionVariants[getDirection(currentCourseLeft)]} 
              animate="visible"
              variants={directionVariants}
              transition={{ duration: 0.5 }}
              className=" font-semibold backdrop-blur-lg w-[85%] sm:w-full  flex flex-col gap-3 justify-start items-start p-3 md:p-5 border border-gray-300 rounded-lg "
            >
              <h2 className='text-[18px]  md:text-xl lg:text-2xl text-[#c43238] font-bold text-start leading-[1.2]'>{leftCourses[currentCourseLeft].name}</h2>
              <p className="-mt-2 text-[12px] md:text-sm    leading-[1.2] text-start xl:pr-20">{leftCourses[currentCourseLeft].quotation}</p>
              <div className='hidden md:flex justify-end items-center md:justify-start md:items-end w-full'>
              <Link to='/Courses'>
      <button className=" px-3 md:px-5 py-1 bg-black text-[16px] md:text-lg hover:bg-red-600 text-white font-semibold rounded-lg transition duration-300"
       style={{
        boxShadow:'0 0 4px 4px rgba(0,0,0,0.10)'
      }}>
          Enroll Now
        </button>
      </Link>
              </div>
              
            </motion.div>
          </div>

          <div className="flex justify-end items-end sm:justify-start sm:items-start w-[100%] pr-4 sm:pr-0  md:w-[45%] xl:w-[50%] ">
            <motion.div
              key={currentCourseRight}
              initial={directionVariants2[getDirection2(currentCourseRight)]} 
              animate="visible"
              variants={directionVariants2}
              transition={{ duration: 0.5 }}
              className="font-semibold relative z-20 backdrop-blur-lg w-[85%] sm:w-full flex flex-col gap-3 justify-start items-start p-3 md:p-5 border border-gray-300 rounded-lg "
            >
               <h2 className='text-[18px] md:text-xl lg:text-2xl text-[#c43238] leading-[1.2] font-bold text-start'>{rightCourses[currentCourseRight].name}</h2>
              <p className="-mt-2  text-[12px] lg:text-sm    leading-[1.2] text-start xl:pr-20">{rightCourses[currentCourseRight].quotation}</p>
              <div className='hidden md:flex justify-end items-center md:justify-start md:items-start w-full'>
              <Link to='/Courses'>
      <button className=" px-3 md:px-5 py-1 bg-black text-[16px] md:text-lg hover:bg-red-600 text-white font-semibold rounded-lg transition duration-300"
       style={{
        boxShadow:'0 0 4px 4px rgba(0,0,0,0.10)'
      }}>
          Enroll Now
        </button>
      </Link>
              </div>
            </motion.div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;


