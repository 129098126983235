export const Blob1 = (props) => (
  <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill="#C43238"
      d="M44.2,-66.5C58.8,-59.3,73.2,-49.7,71.4,-37.4C69.5,-25.1,51.4,-10.1,43.4,2.2C35.4,14.6,37.5,24.4,34.8,33.4C32.1,42.4,24.7,50.5,15.7,52.7C6.8,55,-3.6,51.3,-13.2,47.4C-22.8,43.5,-31.6,39.2,-44.6,33.5C-57.6,27.7,-74.8,20.5,-75.8,11.1C-76.9,1.7,-61.8,-9.8,-53.5,-22.9C-45.1,-36,-43.6,-50.7,-35.8,-61C-28,-71.3,-14,-77.2,0.4,-77.8C14.8,-78.4,29.5,-73.7,44.2,-66.5Z"
      transform="translate(100 100)"
    />
  </svg>
);

export function CircleIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" />
    </svg>
  );
}

export function CodeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="16 18 22 12 16 6" />
      <polyline points="8 6 2 12 8 18" />
    </svg>
  );
}

export function DatabaseIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <ellipse cx="12" cy="5" rx="9" ry="3" />
      <path d="M3 5V19A9 3 0 0 0 21 19V5" />
      <path d="M3 12A9 3 0 0 0 21 12" />
    </svg>
  );
}


