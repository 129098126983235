import React from "react";
import WindowServer from "./Window_Server";

export default function WindowServerLink() {
  return (
    <div>
      <WindowServer />
    </div>
  );
}
