import React from 'react';
import './home.css'

export default function Fifth() {
    return (
        <>
            {/* Fifth Section Start */}
            <div className='grid grid-cols-1 md:grid-cols-2 border mx-4 sm:mx-8 md:mx-16   mt-32 '>
                <div className=' bx3 bg-[#c43238] md:w-[63%] flex justify-center'>
                    <img src='a17.png' className=' img3 mt-0 md:mt-14 z-10 ml-5 h-64   md:h-auto w-48 md:w-auto ' alt='loading' ></img>
                </div>

                <div className='bx2  md:-ml-[165px] lg:-ml-[214px] p-4 sm:p-16 bg-[#1A2942] [#c43238] text-white'>
                    <p className='bx2  text-2xl md:text-3xl lg:text-4xl tracking-wide text-start'>Enroll In Your Favourite Course For Design Your Future </p>
                    <div className='grid grid-cols-1 lg:grid-cols-2 mt-10 gap-5 lg:gap-10 text-[20px] text-start tracking-wide font-extrabold'>
                        <div className='border p-8  rounded-lg bx1'>All Courses curated by Expert Faculty From Bhopal.</div>
                        <div className='border p-8  rounded-lg bx4'>Dedicated 1:1 Doubt Support to ensure you clear all your doubts.</div>
                    </div>
                    <div className='grid grid-cols-1 lg:grid-cols-2 mt-5 lg:mt-10  gap-5 lg:gap-10 text-[20px] text-start tracking-wide font-extrabold'>
                        <div className='border p-8  rounded-lg bx1'>Domain Expert Sessions to boost your skills.</div>
                        <div className='border p-8  rounded-lg bx4'>Live Projects to test your skills against your competitor.</div>
                    </div>
                </div>
            </div>
            {/* Fifth section end  */}
        </>
    )
}
