import React from 'react';
import './home.css'
import { UilMobileAndroidAlt } from '@iconscout/react-unicons'

export default function Counceler() {
    return (
        <>
            {/* <!-- ======= Expert Section ======= --> */}
            <section className='  ' id='' >
                <div className=' grid grid-cols-1 md:grid-cols-2 mt-32 font-sans mx-4 sm:mx-16  rounded-xl bg-gradient-to-r  from-red-700 from-10%  via-red-500 to-red-700 to-90% ' >
                    <div className='block text-white text-start p-10 sm:w-[100%] md:w-[120%] bx1  '>
                        <p className=' font-semibold tracking-wide '>Clear All Your Doubts</p>
                        <p className=' font-bold text-3xl md:text-4xl mt-1'>Speak to Our Career Counsellors</p>
                       <span className='flex ml-9'> <UilMobileAndroidAlt size={48} className='mt-3'/>
                       <p className='font-sans text-2xl md:text-3xl font-bold  mt-[15px] -ml-2'>+91 8349530962</p>
                       </span> 
                    </div>
                    <div className=' flex w-[80%]    md:ml-[60px] lg:ml-[115px] order-first  md:order-last heading2  '>
                        <img src='/coun.png ' className=' h-[290px] -mt-20  '  alt='loading...'></img>
                    </div>
                </div>
            </section>
            {/* Expert  section end  */}
        </>
    )
}
