import React from "react";
import JavaFullstack from "./Java_Fullstack";

export default function JavaFullstackLink() {
  return (
    <div>
      <JavaFullstack />
    </div>
  );
}
