import React from "react";
import "./home.css";

export default function Placement() {
  return (
    <>
      {/* <!-- ======= Our Services Section ======= --> */}
      <section className=" p-1 mt-8">
        <div className="grid grid-cols-1  mt-8 lg:mx-32 sm:mx-16 mx-8 ">
          <div className="p-3 text-center   ">
            <h3 className=" bx1 sm:text-2xl text-xl uppercase font-semibold ">
              PLACEMENT PROCESS
            </h3>
          </div>
        </div>
        <div className="lg:border-2 border-[#c43238] grid grid-cols-1 mx-[600px] justify-center animate-bounce ">
          <div className=" absolute hideen lg:block"></div>
        </div>

        <div className="mt-8 bx5">
          <img src="placement1.png" className="flip" alt="placement"></img>
        </div>
      </section>
      {/* Services or 6th section end  */}
    </>
  );
}
