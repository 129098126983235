import React from "react";
export default function FirstCourse() {
  return (
    <>
      {/* <MetaData title="Netligent - Courses " ></MetaData> */}
      {/* First Section of courses page */}
      <section>
        <div className="grid grid-cols-1  mt-16 lg:mx-32 sm:mx-16  overflow-hidden ">
          <div className="p-3 text-center   ">
            <h3 className=" font-sanf  text-[#c43238] text-2xl sm:text-4xl uppercase  font-bold scaleUp ">
              Our Courses
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-1  -mt-4 lg:mx-32 sm:mx-16 mx-4  overflow-hidden">
          <div className="p-3 text-center   ">
            <h3 className=" font-sans text-lg  text-[#1A2942]  scaleUp  ">
              Develop a solid base for a successful career through professional
              courses of Netligent.
            </h3>
          </div>
        </div>
      </section>
    </>
  );
}
