import React from "react";
import "../home.css";
import { Link } from "react-router-dom";
export default function Courses1({ courses }) {
  return (
    <>
      {/* All Course Section Start  */}

      <section>
        <div
          className={`grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 mt-16 text-base lg:gap-4 gap-8 lg:mx-16 md:mx-14 sm:mx-8 mx-4`}
        >
          {courses.map((course, index) => (
            <div
              key={index}
              className={`box1 mt-${course.margintop} bx${
                index % 2 === 0 ? "2" : "3"
              }`}
            >
              <div
                className={`bg-${course.bgColor} pl-3 pt-6 flex justify-start`}
                alt="loading..."
              >
                <img
                  src={course.image}
                  className="h-12 w-12 rounded-full border-2 bg-white mt-4"
                  alt={`${course.title} `}
                />
                <h2 className="text-start -mt-2 uppercase text-[15px] p-3 text-white mb-3  tracking-widest">
                  {course.title}
                </h2>
              </div>
              {/* For overlay hover effect */}
              <div className="content-overlay hidden lg:block"></div>
              <div className="content-details fadeIn-left text-white hidden lg:block">
                <p className="text-xl italic text-center">Key Features</p>
                <div className="text-white mt-1 text-start">
                  <p className="mt-8 italic">What will I learn?</p>
                </div>
                <ul className="text-[13px] mt-2 list-disc ml-5 justify-center">
                  {course.features.map((feature, index) => (
                    <li key={index} className="text-center">
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link to={course.detailsLink} target="_parent">
                  <div className="p-2 border uppercase text-xs font-bold mt-5 rounded-md cursor-pointer">
                    <span>View Details</span>
                  </div>
                </Link>
              </div>
              {/* For overlay hover effect end */}
              <div className="p-5">
                <h2 className="text-start font-semibold">{course.subtitle}</h2>
                <img
                  src="/clock.png"
                  className="h-6 w-6 mt-3"
                  alt="Clock Icon"
                />
                <p className="text-start ml-9 -mt-6">{course.duration}</p>
                <img src="/book.png" className="h-5 w-5 mt-3" alt="Book Icon" />
                <p className="text-start ml-9 -mt-6">{course.mode}</p>
                <Link to={course.detailsLink} target="_parent">
                  <div className="p-3 lg:hidden block uppercase text-xs font-bold mt-5 rounded-md cursor-pointer hover:bg-black hover:text-white border border-black transition ease-in-out duration-500">
                    <span>View Details</span>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
