import React from "react";
import CarrerFirst from "./careerfirst";
import MetaData from "../../../Layout/Layout";
export default function CareerLink() {
    return (
      <div>
        <MetaData title="Career - Netligent Technologies " ></MetaData>
        <CarrerFirst/>
      </div>
    )
  }