import React from "react";
import PythondeveloperAssociate from "./Py_Associate";

export default function PyAssociateLink() {
  return (
    <div>
      <PythondeveloperAssociate />
    </div>
  );
}
