import React from "react";
import "./home.css";
import { UilArrowRight } from "@iconscout/react-unicons";
import { Link} from "react-router-dom";

export default function Sec() {
  return (
    <>
      {/* Second section start */}
      <section>
        <div className="grid  grid-cols-1 md:grid-cols-3 lg:mx-24 sm:mx-16 mx-8  md:mt-16 mt-10  gap-10 md:text-base ">
          <div className="sm:p-5 p-3   box1  uppercase     rounded-xl ptag1">
            <p className="text-start   ">
              become a <br></br>
              python / django expert{" "}
            </p>
            <Link to="/pages/Courses">
              <p className=" text-[#1A2942] text-end   mr-12 mt-2">
                know more{" "}
              </p>
              <span className=" text-[#1A2942] float-right -mt-6 mr-5 animate-pulse flex space-x-1">
                <UilArrowRight />{" "}
              </span>{" "}
            </Link>
          </div>

          <div className=" p-5  box1  uppercase  ptag1   rounded-xl">
            <p className="text-start ">
              become a <br></br>
              cyber security expert{" "}
            </p>
            <Link to="/pages/Courses">
              <p className=" text-[#1A2942] text-end mr-12 mt-2">know more </p>
              <span className=" text-[#1A2942] float-right -mt-6 mr-5 animate-pulse flex space-x-1">
                <UilArrowRight />{" "}
              </span>{" "}
            </Link>
          </div>

          <div className=" p-5  box1  uppercase   ptag1   rounded-xl ">
            <p className="text-start ">
              become a <br></br>
              ethical hacker expert{" "}
            </p>
            <Link to="/pages/Courses">
              <p className=" text-[#1A2942] text-end mr-12 mt-2 ">know more </p>
              <span className=" text-[#1A2942] float-right -mt-6 mr-5 animate-pulse flex space-x-1">
                <UilArrowRight />{" "}
              </span>{" "}
            </Link>
          </div>
        </div>
      </section>
      {/* Second section end */}
    </>
  );
}
