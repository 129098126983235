import React from "react";
export default function CarrerFirst() {
  return (
    <>
      {/* first Section Of Career */}
      <section className="  ">
        <div className="  font-sanf justify-center mt-52 block  box1 rounded-lg border border-white  mx-8 sm:mx-12 md:mx-16 lg:mx-24">
          <div className=" -mt-52 justify-center flex  z-20 ">
            <img
              src="career6.png"
              className="h-96  z-50 bx2"
              alt="loading.."
            ></img>
          </div>
          <div className="p-5 block justify-center text-center mb-4 mx-0 sm:mx-8 md:mx-16 bx3">
            <h1 className="text-xl sm:text-2xl  md:text-3xl lg:text-4xl font-bold  text-[#c43238]     lg:mt-0 ">
              Career Opportunities{" "}
            </h1>
            <p className="mt-5 text-gray-700 text-start">
              At Netligent Technologies, we are constantly on the lookout for
              bright, talented, and passionate individuals to join our team.
              While we don't have any specific job openings at the moment, we
              always welcome expressions of interest from prospective
              candidates.<br></br> <br></br>
              If you are enthusiastic about IT, passionate about education, and
              excited about shaping the future of technology, we'd love to hear
              from you. Your skills and expertise might be the perfect fit for
              future opportunities at Netligent Technologies.<br></br>
              Feel free to send us your resume and a cover letter on
              <span className="text-[#c43238]">
                ( netligenttechnologies@gmail.com)
              </span>{" "}
              explaining why you'd like to be a part of our dynamic team. We'll
              keep your information on file and reach out when a suitable
              position becomes available.<br></br> <br></br>
              Netligent Technologies is committed to providing equal employment
              opportunities, and we embrace diversity in our workforce. We look
              forward to connecting with exceptional talent in the future. Stay
              tuned for updates on job openings and career opportunities on this
              page.<br></br> <br></br>
              In the meantime, if you have any questions or would like to learn
              more about our institute, training programs, or services, please
              don't hesitate to contact us. We're here to help you on your
              journey to a successful career in IT.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
