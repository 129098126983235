'use client';

import { useRef, useEffect } from 'react';
import { LocomotiveScrollProvider } from 'react-locomotive-scroll';

export default function ClientLocomotiveScroll({ children }) {
  const containerRef = useRef(null);

  useEffect(() => {
    // Dynamically import Locomotive Scroll styles
    import('locomotive-scroll/dist/locomotive-scroll.css');
  }, []);

  return (
    <LocomotiveScrollProvider
      options={{
        smooth: true,
        multiplier: 0.7, 
      }}
      containerRef={containerRef}
      watch={[]}
    >
      <div data-scroll-container ref={containerRef}>
        {children}
      </div>
    </LocomotiveScrollProvider>
  );
}