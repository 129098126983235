import React from "react";
export default function Gal() {
  return (
    <>
      {/* Gallery Section Start */}
      <section className="bg-[#eeeeee]">
        <div className="grid grid-cols-1  lg:mx-32 sm:mx-16  overflow-hidden  ">
          <div className="p-3 text-center  mt-16 ">
            <h3 className=" font-sanf  text-[#c43238] text-2xl sm:text-4xl bx1 uppercase  font-bold  ">
              Our Gallery
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-1  -mt-4 lg:mx-32 sm:mx-16 mx-4  overflow-hidden">
          <div className="p-3 text-center   ">
            <h3 className=" font-sans text-lg  text-[#1A2942]  bx4  ">
              Infrastructure Of Netligent Technologies
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-2  md:grid-cols-4 mx-4 sm:mx-16 gap-3 sm:gap-6 mt-16">
          <div className=" col-span-2  border-red-500 ">
            <img
              src="/i1.JPG"
              className="scaleUp h-auto w-auto rounded-xl"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-gray-600">
            <img
              src="/i2.JPG"
              className=" h-auto w-full rounded-xl bx1"
              alt="loading..."
            ></img>
            <img
              src="i4.JPG"
              className=" h-auto w-auto rounded-xl mt-3 sm:mt-6 bx4"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-black  ">
            <img
              src="i3.JPG"
              className=" auto w-auto rounded-xl bx2"
              alt="loading..."
            ></img>
            <img
              src="i11.JPG"
              className=" h-auto w-full rounded-xl mt-3 sm:mt-6 bx3"
              alt="loading..."
            ></img>
          </div>
        </div>

        <div className="grid grid-cols-2  md:grid-cols-4 mx-4 sm:mx-16 gap-3 sm:gap-6 mt-6">
          <div className="   border-red-500 ">
            <img
              src="i6.JPG"
              className=" h-auto w-auto rounded-xl bx3"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-gray-600">
            <img
              src="i10.JPG"
              className=" h-auto w-full rounded-xl bx4"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-black">
            <img
              src="i8.JPG"
              className=" auto w-auto rounded-xl bx4"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-green-800">
            <img
              src="i9.JPG"
              className=" h-auto w-auto rounded-xl bx3"
              alt="loading..."
            ></img>
          </div>
        </div>

        <div className="grid grid-cols-2  md:grid-cols-4 mx-4 sm:mx-16 gap-3 sm:gap-6 mt-6">
          <div className="   border-red-500 ">
            <img
              src="i12.JPG"
              className=" h-auto w-auto rounded-xl bx2"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-gray-600">
            <img
              src="i13.JPG"
              className=" h-auto w-full rounded-xl bx1"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-black">
            <img
              src="i14.JPG"
              className=" auto w-auto rounded-xl bx2"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-green-800">
            <img
              src="i15.JPG"
              className=" h-auto w-auto rounded-xl bx4"
              alt="loading..."
            ></img>
          </div>
        </div>

        <div className="grid grid-cols-2  md:grid-cols-4 mx-4 sm:mx-16 gap-3 sm:gap-6 mt-6 ">
          <div className="  border-gray-600">
            <img
              src="i17.JPG"
              className=" h-auto w-full rounded-xl bx3"
              alt="loading..."
            ></img>
            <img
              src="i18.JPG"
              className=" h-auto w-auto rounded-xl mt-3 sm:mt-6 bx2"
              alt="loading..."
            ></img>
          </div>
          <div className="  border-black">
            <img
              src="i19.JPG"
              className=" auto w-auto rounded-xl bx1"
              alt="loading..."
            ></img>
            <img
              src="i20.JPG"
              className=" h-auto w-full rounded-xl mt-3 sm:mt-6 bx3"
              alt="loading..."
            ></img>
          </div>
          <div className=" col-span-2  border-red-500  mb-28">
            <img
              src="i16.JPG"
              className=" h-auto w-auto rounded-xl bx4"
              alt="loading..."
            ></img>
          </div>
        </div>
      </section>
    </>
  );
}
