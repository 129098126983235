import React, { useEffect } from "react";
import Glide from "@glidejs/glide";

export default function CarouselControlsOutside() {
  useEffect(() => {
    const slider = new Glide(".glide-04", {
      type: "carousel",
      focusAt: "center",
      perView: 3,
      autoplay: 3500,
      animationDuration: 700,
      gap: 24,
      classNames: {
        nav: {
          active: "[&>*]:bg-wuiSlate-700",
        },
      },
      breakpoints: {
        1024: {
          perView: 2,
        },
        640: {
          perView: 1,
        },
      },
    }).mount();

    return () => {
      slider.destroy();
    };
  }, []);

  return (
    <>
      <section className="bg-[#F2F4F8]">
        {/*<!-- Component: Carousel with controls outside --> */}
        <div className="glide-04 relative w-full pt-32   bg-[#F2F4F8]">
          {/*    <!-- Slides --> */}
          <div className="overflow-hidden   " data-glide-el="track">
            <ul className="whitespace-no-wrap   flex-no-wrap [backface-visibility: hidden] [transform-style: preserve-3d] [touch-action: pan-Y] [will-change: transform] relative flex w-full overflow-hidden p-0">
              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="jyoti1.jpeg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">Jyoti Verma</h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        The facilities are modern, well-equipped, and conducive
                        to focused learning. The support staff and faculty are
                        approachable, creating a positive atmosphere that
                        enhances the overall learning experience.
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div class="block  rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="shreya1.jpg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">Shreya Jain</h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        There is a valuable and positive learning experience
                        with this individual. Their teaching skills are
                        commendable, and what stands out the most is the high
                        quality of instruction provided by the teacher. Best IT
                        Institute in Bhopal
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="krishnpal1.jpeg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">
                        Krishnpal Vishwakarma
                      </h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        This institute has strong industry connections, This
                        personalized approach to placement support instills
                        confidence in students as they transition from training
                        to professional roles. Best Hacking Institute.
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="abid1.jpg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">Abid Rehman</h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        My time at Netligent has been nothing short of
                        exceptional, The institute's emphasis on quality
                        training, coupled with effective placement support, sets
                        it apart as a premier destination for those seeking a
                        better job. Best Python coaching in Bhopal
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="nagendra1.jpg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">
                        Nagendra Singh
                      </h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        In Netligent, The trainers are not only highly
                        knowledgeable in their respective fields but also
                        possessed a remarkable ability to convey complex
                        concepts in a clear and understandable manner.Best for
                        Web Development Courses in Bhopal
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="sajid2.jpeg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">Mohd Sajid</h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        Netligent goes above and beyond by providing dedicated
                        placement assistance, I recommend Netligent to anyone
                        looking to embark on a successful and rewarding journey
                        in the IT industry.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="kuldeep5.jpg" className="w-24" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">
                        Kuldeep Maurya
                      </h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        The instructors' expertise, hands-on approach, and a
                        well-rounded curriculum provided a solid foundation. The
                        institute's commitment to placement support, modern
                        facilities,
                      </p>
                    </div>
                  </div>
                </div>
              </li>

              <li>
                <div>
                  <div class="block rounded-lg bg-white shadow-lg dark:bg-neutral-700 dark:shadow-black/30">
                    <div class="h-28 overflow-hidden rounded-t-lg bg-[#1A2942]"></div>
                    <div class="mx-auto -mt-12 w-24 overflow-hidden rounded-full border-2 border-white bg-white dark:border-neutral-800 dark:bg-neutral-800">
                      <img src="dhara1.jpg" alt="loading" />
                    </div>
                    <div class="p-6">
                      <h4 class="mb-4 text-2xl font-semibold">Dhara Jain</h4>
                      <hr />
                      <p class="mt-4">
                        <svg
                          xmlns=""
                          fill="currentColor"
                          class="inline-block h-7 w-7 pr-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M13 14.725c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275zm-13 0c0-5.141 3.892-10.519 10-11.725l.984 2.126c-2.215.835-4.163 3.742-4.38 5.746 2.491.392 4.396 2.547 4.396 5.149 0 3.182-2.584 4.979-5.199 4.979-3.015 0-5.801-2.305-5.801-6.275z" />
                        </svg>
                        I recently had the privilege of enrolling in a training
                        program at Netligent, and I must say it was a truly
                        enriching experience. Netligent exceeded my expectations
                        in several key aspects.
                      </p>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          {/*    <!-- Controls --> */}
          <div
            className="flex w-full items-center justify-center gap-2 p-4"
            data-glide-el="controls"
          >
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
              data-glide-dir="<"
              aria-label="prev slide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
              >
                <title>prev slide</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18"
                />
              </svg>
            </button>
            <button
              className="inline-flex h-8 w-8 items-center justify-center rounded-full border border-slate-700 bg-white/20 text-slate-700 transition duration-300 hover:border-slate-900 hover:text-slate-900 focus-visible:outline-none lg:h-12 lg:w-12"
              data-glide-dir=">"
              aria-label="next slide"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5"
              >
                <title>next slide</title>
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </button>
          </div>
        </div>
      </section>
      {/*<!-- End Carousel with controls outside --> */}
    </>
  );
}
