import React from "react";
import "./home.css";

export default function Fourth() {
  return (
    <>
      {/* Services or fourth section start */}
      <section>
        <div className="grid  lg:grid-cols-2 grid-cols-1  sm:mx-16 mx-4 sm:mt-24  mt-16  text-black    ">
          <div className="flex justify-center">
            <img src="s1.jpg" width="" className="img3 " alt='loading...'></img>
          </div>
          <div className="text-start justify-center md:p-16 sm:p-8 p-4 mb-10 back3  ">
            <div className="    ">
              <h1 className="text-2xl heading3  md:text-3xl lg:text-4xl font-bold  text-[#c43238]    mt-10 lg:mt-0 ">
                Carry Your Goals And Secure{" "}
              </h1>
              <h1 className="text-[#1A2942] heading3 text-2xl  md:text-3xl lg:text-4xl font-bold">
                {" "}
                A Better Future With Us
              </h1>
            </div>

            <p className="mt-8 tracking-wider ptag2">
              Netligent is a Best IT Training Institute In Bhopal, It's gives
              you best IT Training and long-term success by leveraging our
              expertise and conceptual understanding. Our goal is to carry out
              your success, and your faith is our greatest strength. It's never
              been easier to connect with consulting services,{" "}
            </p>

            <div className="  mt-8 grid sm:grid-cols-3 grid-cols-1">
              <div className="flex justify-center   mt-2 ">
                <img
                  src="computer1.gif"
                  className="h-9 flex heading1"
                  width="35"
                  alt='loading...'
                ></img>

                <p className=" absolute mt-12 text-[13px] text-[#1A2942] heading2">
                  Know About Us
                </p>
              </div>
              <div className="flex mt-8 sm:mt-0 justify-center p-2 gap-5 ">
                <img src="gif2.gif" width="35" className="heading1" alt='loading...'></img>
                <p className="absolute mt-12  text-[13px] text-[#1A2942] heading2">
                  Connect With Us
                </p>
              </div>

              <div className="flex mt-8 sm:mt-0 justify-center  p-2 gap-5 ">
                <img src="growth.gif" width="35" className="heading1" alt='loading...'></img>
                <p className="absolute mt-12  text-[13px] text-[#1A2942] heading2">
                  Grow With Us
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Services or fourth section end */}
    </>
  );
}
