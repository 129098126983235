import React from "react";
import AboutFirst from "./aboutfirst";
import AboutSec from "./aboutses";
import Team from "./team";
import AboutFourth from "./aboutfourth";
import AboutFifth from "./aboutfifth";
import Counceler from "../counceler"
import AboutSix from "./aboutsix";
import Connect from "./coonect";
import MetaData from "../../../Layout/Layout";

export default function AboutLink() {
    return (
      <div>
      <MetaData title="About - Netligent Technologies " ></MetaData>
      <AboutFirst/>
      <AboutSec/>
      <Team/>
      <AboutFourth/>
      <AboutFifth/>
      <Counceler/>
      <AboutSix/>
      <Connect/>  
      </div>
    )
  }