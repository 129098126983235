import React from "react";
import "./about.css";
export default function AboutFourth() {
  return (
    <>
      {/* Fourth Section(Guiding You To Technology's Future With Trust) Of About Us */}
      <section className="bg-[#F2F4F8]">
        <div className="grid  grid-cols-1 md:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden ">
          <div className=" text-start p-6 sm:p-12 lg:p-16  md:mt-16 lg:mt-24  mt-10 back4">
            <h1 className="text-2xl  md:text-3xl lg:text-4xl font-bold  text-[#c43238]    mt-10 lg:mt-0 bx2">
              Guiding You{" "}
            </h1>
            <h1 className="text-[#1A2942] text-2xl  md:text-3xl lg:text-4xl font-bold bx2">
              To Technology's Future With Trust
            </h1>
            <p className="mt-5  text-black bx3">
              At Netligent Technologies, we take great pride in being your
              trusted conduit to transformative technology. We recognize the
              perpetual evolution of technology and its profound potential to
              revolutionize training and industries. As a leading IT Training
              Institute, our unwavering commitment is to stay at the forefront
              of these advancements and mentor our students through the
              ever-shifting landscape of transformative technology.
            </p>
          </div>

          <div className=" items-center flex justify-center   md:mt-16  back3   ">
            <img src="a8.png" className="bx3" alt="loading.."></img>
          </div>
        </div>
      </section>
    </>
  );
}
