import React from "react";
import CyberSecurityAssociate from "./csac";
import WhyThisCourse from "./whythiscourse";

export default function CSACDetailsLink() {
  const accordionItems = [
    {
      title: "Feature 1",
      content:
        "Our courses are meticulously designed by industry experts to ensure that you acquire the most relevant and up-to-date skills demanded by todays job market. We prioritize practical knowledge that directly translates into real-world success.    ",
    },
    {
      title: "Feature 2",
      content:
        "Our courses integrate cutting-edge industry practices, preparing you for the challenges and opportunities of a rapidly evolving professional landscape.",
    },
    {
      title: "Feature 3",
      content:
        "We understand the diverse needs of our students. Thats why we offer flexible learning options, including in-person classes, live online sessions, and recorded lectures. Choose the format that best suits your schedule and learning preferences.",
    },
    {
      title: "Feature 2",
      content:
        "Your success is our priority. Receive personalized career guidance and support from our dedicated team. We assist you in identifying career opportunities, refining your resume, and preparing for interviews, ensuring you are well-equipped for success in the job market.",
    },
    {
      title: "Feature 3",
      content:
        "Choose Netligent Courses for an enriching educational experience that goes beyond the traditional classroom. Join us in unlocking your full potential and building a successful career in your chosen field.",
    },
  ];

  const carouselSlides = [
    " Your text here",
    "Slide 2: Your text here",
    "Slide 3: Your text here",
  ];
  return (
    <div>
      <CyberSecurityAssociate />
      <WhyThisCourse items={accordionItems} slides={carouselSlides} />
    </div>
  );
}
