import React, {useEffect} from "react";
import "./App.css";
import ClientLocomotiveScroll from "./components/ClientLocomotiveScroll";
import Header from "./components/Header/Header";
import LandingPage from "./pages/home/landing";
import Footer from "./components/Footer/Footer";
import CourseLink from "./pages/home/Courses/link";
import GalleryLink from "./pages/home/Gallery/link";
import ContactLink from "./pages/home/Contact/link";
import AboutLink from "./pages/home/About/link";
import CareerLink from "./pages/home/Career/link";
import CSACDetailsLink from "./pages/home/Courses/Course Details/link";
import CsProfessionalLink from "./pages/home/Courses/Course Details/link1";
import CyberSecurityExpert from "./pages/home/Courses/Cs_Expert";
import ServerAssociateLink from "./pages/home/Courses/Course Details/link3";
import PyFullstackLink from "./pages/home/Courses/Course Details/link4";
import JavaFullstackLink from "./pages/home/Courses/Course Details/link5";
import MERNStackLink from "./pages/home/Courses/Course Details/link6";
import PyAssociateLink from "./pages/home/Courses/Course Details/link7";
import ReactDeveloperLink from "./pages/home/Courses/Course Details/link9";
import WindowServerLink from "./pages/home/Courses/Course Details/link10";
import RedhatLink from "./pages/home/Courses/Course Details/link11";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CCNAExpertLink from "./pages/home/Courses/Course Details/link8";

const Routing = () => {
  // useEffect(() => {
  //   (async () => {
  //     const { default: LocomotiveScroll } = await import("locomotive-scroll");
  //     const locomotiveScroll = new LocomotiveScroll();
  //   })();
  // }, []);

  return (
    <Routes>
      <Route path="/" element={<LandingPage />}>
        {" "}
      </Route>
      <Route path="/Courses" element={<CourseLink />}>
        {" "}
      </Route>
      <Route path="/Gallery" element={<GalleryLink />}>
        {" "}
      </Route>
      <Route path="/Contact" element={<ContactLink />}>
        {" "}
      </Route>
      <Route path="/About" element={<AboutLink />}>
        {" "}
      </Route>
      <Route path="/Career" element={<CareerLink />}>
        {" "}
      </Route>
      <Route
        path="/Cyber-Security-Associate-Course"
        element={<CSACDetailsLink />}
      >
        {" "}
      </Route>
      <Route
        path="/Cyber-Security-Professional-Course"
        element={<CsProfessionalLink />}
      >
        {" "}
      </Route>
      <Route
        path="/Cyber-Security-Expert-Course"
        element={<CyberSecurityExpert />}
      >
        {" "}
      </Route>

      <Route
        path="/Server-&-Cloud-Associate-Course"
        element={<ServerAssociateLink />}
      >
        {" "}
      </Route>
      <Route
        path="/Python-Fullstack-Developer-Course"
        element={<PyFullstackLink />}
      >
        {" "}
      </Route>
      <Route
        path="/Java-Fullstack-Developer-Course"
        element={<JavaFullstackLink />}
      >
        {" "}
      </Route>
      <Route path="/MERN-Stack-Developer-Course" element={<MERNStackLink />}>
        {" "}
      </Route>
      <Route
        path="/Python-Developer-Associate-Course"
        element={<PyAssociateLink />}
      >
        {" "}
      </Route>
      <Route path="/CCNA-Exper-Course" element={<CCNAExpertLink />}>
        {" "}
      </Route>
      <Route path="/React-Developer-Course" element={<ReactDeveloperLink />}>
        {" "}
      </Route>
      <Route path="/Window-Server-Course" element={<WindowServerLink />}>
        {" "}
      </Route>
      <Route path="/Redhat-Expert-Course" element={<RedhatLink />}>
        {" "}
      </Route>
    </Routes>
  );
};

function App() {
  
  return (
    <div className="App">
      <ClientLocomotiveScroll>
      <Router>
        <Header />

        <Routing />

        <Footer />
      </Router>
      </ClientLocomotiveScroll> 
    </div>
  );
}

export default App;
