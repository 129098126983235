import React from "react";
import {
  UilPhone,
  UilWhatsapp,
  UilLocationPoint,
  UilEnvelopeAlt,
} from "@iconscout/react-unicons";

export default function Map() {
  return (
    <>
      {/* Map Section Start */}
      <section className="slide-up">
        <div
          className="grid grid-cols-1   lg:grid-cols-2  mt-32 mx-4 sm:mx-16 md:mx-32 rounded-3xl box1 
            bg-[#1A2942] "
        >
          <div className=" order-2 lg:order-1 bx1">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3664.3540488865033!2d77.39979567397286!3d23.30290970581109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x23b7651911d6c5e1%3A0x7ca12f68c789ae60!2sNetligent%20Technologies%20LLP%20-%20Cyber%20Security%20%7C%20Ethical%20Hacking%20%7C%20Python%20%7C%20Java%20%7C%20Web%20Development%20%7C%20Job%20Oriented%20Training!5e0!3m2!1sen!2sin!4v1696445785924!5m2!1sen!2sin"
              allowfullscreen=""
              title='google map'
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              className="h-[250px] sm:h-[300px] md:h-[350px] lg:h-[450px] w-full"
            ></iframe>
          </div>
          <div className=" block font-sans  lg:order-2 order-1     ">
            <div className="   rounded-xl  flex justify-start mt-16 lg:mt-0 ml-8  sm:ml-16">
              <div className=" h-12 w-12 flex justify-center bg-white rounded-lg">
                <UilLocationPoint className=" mt-3 text-[#1A2942]  " />
              </div>
              <div className="ml-3 block text-start ">
                <p className="text-lg font-semibold text-white -mt-1 ">
                  Location
                </p>
                <p className=" text-xs text-white">
                  Building 4, Krishak Nagar,<br></br> Near Karond Square, Bhopal
                  M.P.
                </p>
              </div>
            </div>

            <div className="   rounded-xl flex justify-start mt-10 ml-8  sm:ml-16">
              <div className=" h-12 w-12 flex justify-center bg-white rounded-lg">
                <UilPhone className=" mt-3 text-[#1A2942]  " />
              </div>
              <div className="ml-3 block text-start ">
                <p className="text-lg font-semibold text-white  ">Contact</p>
                <p className=" text-xs text-white">+91 83495 30982</p>
              </div>
            </div>

            <div className="   rounded-xl flex justify-start mt-10 ml-8  sm:ml-16">
              <div className=" h-12 w-12 flex justify-center bg-white rounded-lg">
                <UilWhatsapp className=" mt-3 text-[#1A2942]  " />
              </div>
              <div className=" ml-3 block text-start ">
                <p className="text-lg font-semibold text-white  ">Whatsapp</p>
                <p className=" text-xs text-white">+91 83495 30962</p>
              </div>
            </div>

            <div className="   rounded-xl flex justify-start mt-10 ml-8  sm:ml-16 mb-16  lg:mb-0">
              <div className=" h-12 w-12 flex justify-center bg-white rounded-lg">
                <UilEnvelopeAlt className=" mt-3 text-[#1A2942]  " />
              </div>
              <div className=" ml-3 block text-start ">
                <p className="text-lg font-semibold  text-white ">
                  Technical support
                </p>
                <p className=" text-xs text-white">
                  netligenttechnologies@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
