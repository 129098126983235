import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import {
  UilUser,
  UilEnvelope,
  UilPhone,
  UilBookOpen,
  UilCommentAltMessage,
} from "@iconscout/react-unicons";

export default function Form(props) {
  const [state, handleSubmit] = useForm("xjvnbvjy");
  if (state.succeeded) {
    return (
      <p>
        <Form msg="Thank You For Contact Us ! We Will Reach You Soon" />
      </p>
    );
  }

  return (
    <>
      {/* Contact Us Form Section */}
      <section className="">
        <div className="grid grid-cols-1 lg:grid-cols-2   mt-16 mx-8 sm:mx-16 md:mx-32 lg:mx-16 font-sans box1 rounded-2xl ">
          <div className="p-5 sm:p-10 ">
            <p className="text-start text-3xl sm:text-4xl font-bold leading-normal text-[#1A2942] bx2">
              Get in Contact Whenever You Like
            </p>
            <p className="text-lg  mt-5 text-start text-[#c43238] bx2">
              Send A Message To Resolve Your Query?
            </p>

            <img src="c11.png" className="flip " alt="loading..." />
          </div>
          <div className=" p-5 sm:p-10 md:p-16  ">
            <form onSubmit={handleSubmit} className=" ">
              <div class="relative z-0 w-full mb-6 group bx2">
                <input
                  type="text"
                  name="userName"
                  id="name"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0
                         border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#1A2942] 
                         dark:focus:border-[#1A2942] focus:outline-none focus:ring-0 focus:border-[#1A2942] 
                         peer"
                  placeholder=" "
                  required
                />
                <ValidationError
                  prefix="Usename"
                  field="userName"
                  errors={state.errors}
                />
                <label
                  for="floating_email"
                  class="peer-focus:font-medium peer-focus:text-xl  absolute text-lg text-gray-500 dark:text-gray-400
       duration-300 transform -translate-y-6 scale-75 -top-3 left-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#c43238]
        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
        peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  {" "}
                  <UilUser /> Full Name
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group mt-8 bx2">
                <input
                  type="email"
                  name="email"
                  id="floating_email"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent 
                   border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#1A2942] 
                   dark:focus:border-[#1A2942] focus:outline-none focus:ring-0 focus:border-[#1A2942] peer"
                  placeholder=" "
                  required
                />
                <ValidationError
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
                <label
                  for="floating_email"
                  class="peer-focus:font-medium peer-focus:text-xl  absolute text-lg text-gray-500 dark:text-gray-400
       duration-300 transform -translate-y-6 scale-75 -top-3 left-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#c43238]
        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
        peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  <UilEnvelope /> E-mail Address
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group mt-8 bx4">
                <input
                  type="text"
                  name="mobile"
                  id="mobile_number"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900
       bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#1A2942]
        dark:focus:border-[#1A2942] focus:outline-none focus:ring-0 focus:border-[#1A2942] peer"
                  placeholder=" "
                  required
                />
                <ValidationError
                  prefix="Mobile"
                  field="mobile"
                  errors={state.errors}
                />
                <label
                  for="floating_email"
                  class="peer-focus:font-medium peer-focus:text-xl  absolute text-lg text-gray-500 dark:text-gray-400
       duration-300 transform -translate-y-6 scale-75 -top-3 left-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#c43238]
        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
        peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  {" "}
                  <UilPhone /> Phone no
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group mt-8 bx3">
                <input
                  type="text"
                  name="course"
                  id="course"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900
       bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#1A2942]
        dark:focus:border-[#1A2942] focus:outline-none focus:ring-0 focus:border-[#1A2942] peer"
                  placeholder=" "
                  required
                />
                <ValidationError
                  prefix="Course"
                  field="course"
                  errors={state.errors}
                />
                <label
                  for="floating_course"
                  class="peer-focus:font-medium peer-focus:text-xl  absolute text-lg text-gray-500 dark:text-gray-400
       duration-300 transform -translate-y-6 scale-75 -top-3 left-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#c43238]
        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
        peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  {" "}
                  <UilBookOpen />
                  Course Interested{" "}
                </label>
              </div>
              <div class="relative z-0 w-full mb-6 group mt-8 bx3">
                <input
                  type="text"
                  name="message"
                  id="floating_message"
                  class="block py-2.5 px-0 w-full text-sm text-gray-900
       bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-black dark:border-[#1A2942]
        dark:focus:border-[#1A2942] focus:outline-none focus:ring-0 focus:border-[#1A2942] peer"
                  placeholder=" "
                  required
                />
                <ValidationError
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
                <label
                  for="floating_message"
                  class="peer-focus:font-medium peer-focus:text-xl  absolute text-lg text-gray-500 dark:text-gray-400
       duration-300 transform -translate-y-6 scale-75 -top-3 left-2 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-[#c43238]
        peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 
        peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  {" "}
                  <UilCommentAltMessage />
                  Message
                </label>
              </div>
              <h1 className="mb-5 text-blue-800 text-xl animate-bounce">
                {props.msg}
              </h1>
              <button
                type="submit"
                disabled={state.submitting}
                class="text-white slide-up bg-[#1A2942] focus:ring-4 focus:outline-none
  font-medium rounded-3xl text-lg w-full sm:w-auto px-7 py-2 text-center dark:bg-[#1A2942]
    dark:hover:bg-[#c43238]    "
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
