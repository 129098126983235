import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Navbar() {
  const [openNav, setOpenNav] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 800) {
        setOpenNav(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openNav && !event.target.closest('.mobile-nav') && !event.target.closest('.menu-button')) {
        setOpenNav(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [openNav]);

  const navItems = [
    { name: "Home", path: "/" },
    { name: "About Us", path: "/About" },
    { name: "Courses", path: "/Courses" },
    { name: "Career", path: "/Career" },
    { name: "Gallery", path: "/Gallery" },
    { name: "Blog", path: "https://blog.netligent.com/" },
  ];

  const handleNavigation = (path) => {
    setOpenNav(false);
    navigate(path);
  };

  return (
    <>
      <nav className="sticky top-0 z-30 bg-[#F2F4F8] py-4 px-4 lg:px-8">
        <div className="flex items-center justify-between">
          <Link to="/" className="cursor-pointer">
            <img src="logo.png" className="w-52" alt="Netligent Logo" />
          </Link>
          <div className="hidden lg:flex items-center gap-10">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className="text-black font-semibold font-sans relative group hover:text-[#c43238] duration-400 ease-in-out transition-all"
              >
                {item.name}
                <span className="absolute -bottom-1 left-1/2 w-0 h-0.5 bg-[#c43238] transition-all group-hover:w-full group-hover:left-0 duration-500 ease-in-out"></span>
              </Link>
            ))}
            <Link
              to="/Contact"
              className="text-white bg-[#1A2942] border-2 border-[#1A2942] rounded-full px-4 py-2 hover:text-[#1A2942] hover:bg-white transition duration-300"
            >
              Contact Us
            </Link>
          </div>
          <button
            className="lg:hidden text-black z-50 menu-button w-10 h-10 relative focus:outline-none bg-white rounded-full shadow-lg"
            onClick={() => setOpenNav(!openNav)}
          >
            <span className="sr-only">Open main menu</span>
            <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 transition-opacity duration-300 ${openNav ? 'opacity-0' : 'opacity-100'}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`h-6 w-6 absolute top-0 left-0 transition-opacity duration-300 ${openNav ? 'opacity-100' : 'opacity-0'}`}
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </div>
          </button>
        </div>
      </nav>
      
      {/* Overlay */}
      <div 
        className={`fixed inset-0 bg-gray-500 transition-opacity duration-300 ease-in-out ${
          openNav ? "opacity-50 z-40" : "opacity-0 z-[-1]"
        }`}
        onClick={() => setOpenNav(false)}
      ></div>

      {/* Mobile Navigation */}
      <div className={`fixed top-0 right-0 h-full w-1/2 bg-white z-50 transform transition-transform duration-300 ease-in-out ${openNav ? 'translate-x-0' : 'translate-x-full'} lg:hidden mobile-nav`}>
        <div className="p-5 flex flex-col gap-5 pt-28">
          <button
            className="absolute top-4 right-4 text-black w-10 h-10 focus:outline-none bg-gray-100 rounded-full shadow-lg transition-colors duration-300 hover:bg-gray-200"
            onClick={() => setOpenNav(false)}
          >
            <span className="sr-only">Close menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          {navItems.map((item, index) => (
            <button
              key={item.name}
              onClick={() => handleNavigation(item.path)}
              className={`block w-full border border-gray-300 pl-5 rounded-sm hover:border-none text-left py-2 text-black font-semibold font-sans transform transition-all duration-400 ease-in-out relative group hover:text-[#c43238] duration-400  ${
                openNav ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
              }`}
              style={{ transitionDelay: `${index * 50}ms` }}
            >
              {item.name}
              <span className="absolute -bottom-1 left-1/2 w-0 h-0.5 bg-[#c43238] transition-all group-hover:w-full group-hover:left-0 duration-500 ease-in-out"></span>
            </button>
          ))}
          <button
            onClick={() => handleNavigation('/Contact')}
            className={`block w-full mt-4 text-center  border-2 border-[#1A2942] bg-[#1A2942] hover:text-[#1A2942] hover:bg-white text-white rounded-full py-2 transform transition-all duration-300 ease-in-out ${
              openNav ? "translate-x-0 opacity-100" : "translate-x-full opacity-0"
            }`}
            style={{ transitionDelay: `${navItems.length * 50}ms` }}
          >
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
}

