import React from 'react';
import Courses1 from './courses';
import FirstCourse from './first';
import Counceler from '../counceler';
import MetaData from "../../../Layout/Layout";

const courses = [
  {
      title: 'Cyber Security Associate Certification Training',
      subtitle:'Cyber Security Associate Course',
      bgColor: '[#1A2942]',
      image: '/cs3.png',
      features: ['20 Weeks Of Learning', '10 Hours Of Assignments', '100% Job Assistance'],
      duration: '5 Months',
      mode: 'Offline/Hybrid',
      detailsLink: '/Cyber-Security-Associate-Course'
  },
  {
    title: 'Cyber Security Professional Certification Training',
    subtitle:'Cyber Security Professional Course',
    bgColor: '[#c43238]',
    image: '/Cs_Professional.png',
    features: ['34 Weeks Of Learning', '20 Hours Of Assignments', '100% Job Assistance'],
    duration: '8 Months',
    mode: 'Offline/Hybrid',
    detailsLink: '/Cyber-Security-Professional-Course'
},
{
  title: 'Cyber Security Expert Certification Training',
  subtitle:'Cyber Security Expert Course',
  bgColor: '[#1A2942]',
  image: '/cs5.png',
  features: ['50 Weeks Of Learning', '30 Hours Of Assignments', '100% Job Assistance'],
  duration: '12 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Cyber-Security-Expert-Course'
},
{
  title: 'Server & Cloud Associate Certification Training',
  subtitle:'Server & Cloud Associate Course ',
  bgColor: '[#c43238]',
  image: '/Cloud.png',
  features: ['22 Weeks Of Learning', '12 Hours Of Assignments', '100% Job Assistance'],
  duration: '5 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Server-&-Cloud-Associate-Course'
},
{
  margintop:'12',
  title: 'Python Full Stack Developer  Certification Training ',
  subtitle:'Python Full Stack Developer Course ',
  bgColor: '[#c43238]',
  image: '/python.png',
  features: ['32 Weeks Of Learning', '18 Hours Of Assignments', '100% Job Assistance'],
  duration: '8 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Python-Fullstack-Developer-Course'
},
{
  margintop:'12',
  title: 'JAVA Full Stack Developer Certification Training',
  subtitle:'JAVA Full Stack Developer Course ',
  bgColor: '[#1A2942]',
  image: '/java1.png',
  features: ['32 Weeks Of Learning', '18 Hours Of Assignments', '100% Job Assistance'],
  duration: '8 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Java-Fullstack-Developer-Course'
},
{
  margintop:'12',
  title: 'MERN Stack Developer Certification Training',
  subtitle:'MERN Stack Developer Course  ',
  bgColor: '[#c43238]',
  image: '/mern.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/MERN-Stack-Developer-Course'
},
{
  margintop:'12',
  title: 'Data Science Certification Training',
  subtitle:'Data Science Course  ',
  bgColor: '[#1A2942]',
  image: '/Data_Science.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Data-Science-Course'
},
//
{
  margintop:'12',
  title: 'CCNA Expert Certification Training ',
  subtitle:'CCNA Course  ',
  bgColor: '[#1A2942]',
  image: '/ccna1.png',
  features: ['7 Weeks Of Learning', '5 Hours Of Assignments', 'No Job Assistance'],
  duration: '2 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/CCNA-Exper-Course'
},
{
  margintop:'12',
  title: 'React Developer Certification Training',
  subtitle:'React Developer Course  ',
  bgColor: '[#c43238]',
  image: '/logo192.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/React-Developer-Course'
},
{
  margintop:'12',
  title: 'Windows Server Certification Training ',
  subtitle:'Windows Server Course  ',
  bgColor: '[#1A2942]',
  image: '/ccna1.png',
  features: ['7 Weeks Of Learning', '5 Hours Of Assignments', 'No Job Assistance'],
  duration: '2 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Window-Server-Course'
},
{
  margintop:'12',
  title: 'Redhat Expert Certification Training',
  subtitle:'Redhat Expert Course  ',
  bgColor: '[#c43238]',
  image: '/logo192.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Redhat-Expert-Course'
},
{
  margintop:'12',
  title: 'Python Developer Associate Certification Training',
  subtitle:'Python Developer Associate Course  ',
  bgColor: '[#c43238]',
  image: '/py.png',
  features: ['11 Weeks Of Learning', '8 Hours Of Assignments', 'No Job Assistance'],
  duration: '3 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Python-Developer-Associate-Course'
},
{
  margintop:'12',
  title: 'MEAN Stack Developer Certification Training',
  subtitle:'MEAN Stack Developer Course  ',
  bgColor: '[#1A2942]',
  image: '/MEAN_Stack.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/MEAN-Stack-Developer-Course'
},
{
  margintop:'12',
  title: 'Machine Learning Certification Training',
  subtitle:'Machine Learning Course  ',
  bgColor: '[#c43238]',
  image: '/Machine_Learning.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Machine-Learning-Course'
},
{
  margintop:'12',
  title: 'Devops Certification Training',
  subtitle:'DevOps Course  ',
  bgColor: '[#1A2942]',
  image: '/Devops.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Devops-Course'
},
{
  margintop:'12',
  title: 'Cloud Computing Certification Training',
  subtitle:'Cloud Computing Course  ',
  bgColor: '[#1A2942]',
  image: '/Cloud_Computing.png',
  features: ['24 Weeks Of Learning', '15 Hours Of Assignments', '100% Job Assistance'],
  duration: '6 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Cloud-Computing-Course'
},
{
  margintop:'12',
  title: 'Java Developer Associate Certification Training',
  subtitle:'Java Developer Associate Course  ',
  bgColor: '[#c43238]',
  image: '/Java_Ass.jpg',
  features: ['11 Weeks Of Learning', '8 Hours Of Assignments', 'No Job Assistance'],
  duration: '3 Months',
  mode: 'Offline/Hybrid',
  detailsLink: '/Java-Developer-Associate-Course'
},
];

export default function CourseLink() {
  // const dispatch = useDispatch();
  // useEffect(()=>{
  //   dispatch();
  // },[dispatch] )
  return (
    <div>
      <MetaData title="Courses - Netligent Technologies " ></MetaData>
      <FirstCourse/>
      <Courses1 courses={courses} />
      <Counceler/>
    
      
    </div>
  )
}
