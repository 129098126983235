import React from "react";
export default function Team() {
  return (
    <>
      {/* 3rd section(Team) Section Of About Us */}
      <section className=" bg-[#F2F4F8]">
        <div className="grid  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden box1 mx-4 sm:mx-8 md:mx-16  ">
          <div className=" items-center flex justify-center     ">
            <img
              src="m1.png"
              className="flip h-80 w-80 sm:h-96 sm:w-96 rounded-full  bg-gray-300 mt-16 lg:mt-0"
              alt="loading.."
            />
          </div>

          <div className=" text-start p-6 md:p-12 lg:p-16 mb-10  md:mb-0  ">
            <h1 className="text-2xl  md:text-3xl lg:text-4xl font-bold  text-[#1A2942] md:-mt-5  lg:mt-0 text-center  lg:text-start scaleUp">
              Mudassir Ahmed Hashmi
            </h1>
            <h1 className="text-[#c43238] mt-2 text-base  md:text-lg lg:text-xl font-bold text-center  lg:text-start scaleUp">
              Managing Director & Technology Evangelist
            </h1>
            <p className="mt-5  text-black  slide-up">
              Mr. Hashmi is an independent computer security consultant with
              wide experience in the field of Internet security and ethical
              hacking from past 15 years. He is running several cyber security
              awareness programs and campaigns at different level. He has also
              designed and conducted various workshops for various corporations
              and educational houses in the area of network security and ethical
              hacking. He provides customized cyber security training and
              consulting solutions to clients all across M.P. He has also
              conducted a lot of seminars and training sessions on various
              topics related to cyber security in various colleges as well as
              for corporate world in India. With a strong belief in the
              integration of security and education, Mudassir, closely works
              with the various organizations and advises them on the design and
              structure of the course material of their computer security
              courses.
            </p>
          </div>
        </div>

        <div className="grid  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden  box1  mx-4 sm:mx-8 md:mx-16  mt-16 ">
          <div className=" text-start p-6 md:p-12 lg:p-16 order-2  lg:order-1  mb-10  md:mb-0  ">
            <h1 className="text-2xl scaleUp md:text-3xl lg:text-4xl font-bold  text-[#1A2942] md:-mt-5  lg:mt-0 text-center  lg:text-start">
              Anish Panicker
            </h1>
            <h1 className="text-[#c43238] scaleUp mt-2 text-base  md:text-lg lg:text-xl font-bold text-center  lg:text-start">
              Program Director & Technology Evangelist
            </h1>
            <p className="mt-5  text-black slide-up ">
              A cyber Security Specialist playing a vital role as consultant and
              wide experience in the field of Cyber Security and Ethical Hacking
              from past 10 years. A speaker and Educator who designed several
              security programs and campaigns at different level. He has also
              designed and conducted various workshops for various corporations
              and educational houses in the area of Cyber Security, Cloud
              Security and Web Application Security. He provides customized
              cyber security training and consulting solutions to clients all
              across M.P. He has also conducted a lot of seminars and training
              sessions on various topics related to cyber security in various
              colleges as well as for corporate world in India. Combining
              security and education, Anish Panicker, closely works with the
              various organizations and advises them on the design and structure
              of the course material of their IT Security curriculum.
            </p>
          </div>

          <div className=" items-center   justify-center block order-1 lg:order-2 ">
            <center>
              {" "}
              <img
                src="m.png"
                className="flip h-80 w-80 sm:h-96 sm:w-96 rounded-full bg-gray-300  mt-16 lg:mt-0"
                alt="loading.."
              />
            </center>
          </div>
        </div>
      </section>
    </>
  );
}
