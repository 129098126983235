import React, { useState, useEffect } from "react";
import "./TextCarousel.css";

export default function WhyThisCourse({ items, slides }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  return (
    <>
      {/* Why this course */}
      <section>
        <div className="grid grid-cols-2 border-2 border-black mt-10 mx-32">
          <div className="border-yellow-300 border-2 w-full md:w-[600px] lg:w-[670px]    ">
            {items.map((item, index) => (
              <div key={index} className="mb-2">
                <div
                  className={`flex justify-between items-center p-2 cursor-pointer bg-gray-200 ${
                    index === activeIndex ? "rounded-t" : "rounded"
                  }`}
                  onClick={() => handleClick(index)}
                >
                  <p className="text-lg font-semibold">{item.title}</p>
                  <span className="font-bold text-2xl">
                    {index === activeIndex ? "-" : "+"}
                  </span>
                </div>
                {index === activeIndex && (
                  <div className="p-2 t bg-gray-100 border border-t-0 rounded-b">
                    <p>{item.content}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="text-carousel border-2 border-red-600 ml-32 w-48 ">
            <div
              className="text-carousel-inner "
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              {slides.map((slide, index) => (
                <div key={index} className="text-carousel-item ">
                  {slide}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
