import React from "react";

export default function ContactSec() {
  return (
    <>
      {/* Sec Section Of Contact page */}
      <section className="bg-[#eeeeee] ">
        <div className="grid grid-cols-1 lg:grid-cols-2 mx-4 sm:mx-16 ">
          <div className=" lg:p-10  -mt-3  sm:mt-8  md:mt-16 lg:order-1 order-2   sm:mb-24 lg:mb-0 ">
            <video
              src="call.mp4"
              className="w-full bx2  h-[450px] lg:h-80"
            ></video>
          </div>
          <div className=" block bx3  mt-8 sm:mb-0 sm:p-10 sm:mt-16  md:mt-10 lg:mt-28   lg:mb-24 lg:order-2  order-1">
            <h3 className='  text-[#c43238] text-2xl sm:text-4xl uppercase  font-bold md:text-center  lg"text-start '>
              Initial phone call
            </h3>
            <p className="mt-6 md:text-center lg:text-start text-lg ">
              Our initial call will take 15-30 minutes. We will ask you a series
              of questions to determine your goals for the website, key
              functional requirements you have, your timeline and other
              important considerations that will affect the scope of the
              project.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
