import React from "react";
import CCNAExpert from "../CCNA_Expert";

export default function CCNAExpertLink() {
  return (
    <div>
      <CCNAExpert />
    </div>
  );
}
