import React from "react";
import ReactDeveloper from "./React_Developer";

export default function ReactDeveloperLink() {
  return (
    <div>
      <ReactDeveloper />
    </div>
  );
}
