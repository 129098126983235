import React from "react";
export default function AboutSix() {
  return (
    <>
      {/* Six Section(why choose us) Of About Us */}
      <section className="bg-[#F2F4F8]   ">
        <div className="grid grid-cols-1 lg:grid-cols-2  bg-[#F2F4F8]  ">
          <div className="mt-10 sm:mt-28 flex justify-center p-5 ">
            <img src="wcu1.png" className="bx2 " alt="loading..."></img>
          </div>

          <div className=" sm:mt-0 md:mt-0 lg:mt-28 p-5 sm:p-24 lg:p-10">
            <h1 className="text-2xl  md:text-3xl lg:text-4xl font-bold  text-[#1A2942]  text-start  sm:-mt-10 md:-mt-10 lg:mt-0 bx1">
              Why We're the Ideal Option?
            </h1>
            <p className="mt-5 text-start text-black  text-lg bx4">
              At Netligent Technologies, we specialize in smart IT training,
              tailoring our approach to enhance your skills and facilitate
              successful placement opportunities, It is a Best IT Training
              Institute for Web development, Cyber Security in Bhopal.
            </p>

            <div className="grid grid-cols-1 overflow-hidden sm:grid-cols-2 gap-4 sm:gap-7 md:gap-14 lg:gap-7  mt-8 lg:mt-6  ">
              <div className=" p-4 justify-center items-center bg-white rounded-sm box1 bx1 ">
                <div className="flex justify-center items-center">
                  <img
                    src="training.gif"
                    className=" h-16 w-16"
                    alt="loading.."
                  ></img>
                </div>
                <p className="text-center font-sanf text-lg mt-1 text-[#c43238] ">
                  {" "}
                  Industry Learning
                </p>
                <p className="text-center font-sanf text-sm text-gray-800 mt-1">
                  Accurat Industry Training
                </p>
              </div>

              <div className=" p-4 justify-center items-center  bg-white rounded-sm box1 bx4 ">
                <div className=" flex justify-center items-center">
                  <img
                    src="web.gif"
                    className="h-16 w-16 bx3"
                    alt="loading.."
                  ></img>
                </div>
                <p className="text-center font-sanf text-lg mt-1 text-[#c43238]">
                  Up-to-Date Technology
                </p>
                <p className="text-center font-sanf text-sm text-gray-800 mt-1">
                  Next-Generation Technology
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 overflow-hidden sm:grid-cols-2 gap-4  sm:gap-7  md:gap-14 lg:gap-7 mt-4 sm:mt-7  md:mt-10 lg:mt-6  ">
              <div className=" p-4 justify-center items-center bg-white rounded-sm box1 bx4">
                <div className="flex justify-center items-center">
                  <img src="user2.gif" className="" alt="loading.."></img>
                </div>
                <p className="text-center font-sanf text-lg mt-1 text-[#c43238]">
                  Expert Solutions
                </p>
                <p className="text-center font-sanf text-sm text-gray-800 mt-1">
                  Exact Expert Answers
                </p>
              </div>

              <div className=" p-4  justify-center items-center bg-white rounded-sm box1 bx1">
                <div className="flex justify-center items-center">
                  <img src="clock1.gif" className="" alt="loading"></img>
                </div>
                <p className="text-center font-sanf text-lg mt-1 text-[#c43238] ">
                  24/7 Availability{" "}
                </p>
                <p className="text-center font-sanf text-sm text-gray-800 mt-1">
                  Expert Availability 24/7
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
