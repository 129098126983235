import React from "react";
import { useForm, ValidationError } from "@formspree/react";
export default function Connect(props) {
  const [state, handleSubmit] = useForm("xjvnbvjy");
  if (state.succeeded) {
    return (
      <p>
        <Connect msg="Thank You For Contact Us ! We Will Reach You Soon" />
      </p>
    );
  }
  return (
    <>
      {/* Seventh Section(Connect) Of About Us */}
      <section className="bg-[#F2F4F8]  ">
        <div className="flex  justify-center sm:-mt-10 md:-mt-20 lg:-mt-10 p-5 sm:p-10 md:p-20  overflow-hidden font-sanf ">
          <img
            src="s21.png"
            className="z-20 hidden sm:block sm:h-[350px] md:h-[400px] mt-44 bx1"
            alt="loading.."
          ></img>
          <div class=" bg-gray-100 py-6 flex flex-col justify-start mt-5 sm:mt-0  sm:py-12 bx2">
            <div class="relative py-3 sm:max-w-xl sm:mx-auto bx4 ">
              <div class="absolute inset-0 bg-gradient-to-r from-blue-300 to-[#c43238] shadow-lg transform  skew-y-0 rotate-6 rounded-xl sm:rounded-3xl"></div>
              <div class="relative px-4 py-10 bg-white shadow-lg rounded-xl sm:rounded-3xl sm:p-20">
                <div class="max-w-md mx-auto">
                  <div>
                    <h1 class="text-2xl font-semibold">
                      Join Our Network Today{" "}
                    </h1>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="divide-y divide-gray-200">
                      <div
                        class="mt-5 text-base leading-6 space-y-4 text-gray-700
                                             sm:text-lg sm:leading-7"
                      >
                        <div class="relative">
                          <input
                            autocomplete="off"
                            id="name"
                            type="text"
                            name="userName"
                            class="peer placeholder-transparent 
                                                     h-10 w-full border-b-2 border-gray-300 text-gray-900 
                                                     focus:outline-none focus:borer-rose-600"
                            placeholder="Email address"
                            required
                          />
                          <ValidationError
                            prefix="Usename"
                            field="userName"
                            errors={state.errors}
                          />
                          <label
                            for="email"
                            class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                          >
                            Full Name
                          </label>
                        </div>
                        <div class="relative">
                          <input
                            required
                            autocomplete="off"
                            id="email"
                            type="email"
                            name="email"
                            class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="Password"
                          />
                          <ValidationError
                            prefix="Email"
                            field="email"
                            errors={state.errors}
                          />
                          <label
                            for="password"
                            class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                          >
                            E-mail Address
                          </label>
                        </div>

                        <div class="relative">
                          <input
                            required
                            autocomplete="off"
                            id="phone"
                            type="text"
                            name="mobile"
                            class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="Password"
                          />
                          <ValidationError
                            prefix="Mobile"
                            field="mobile"
                            errors={state.errors}
                          />
                          <label
                            for="password"
                            class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                          >
                            Phone No
                          </label>
                        </div>

                        <div class="relative">
                          <input
                            required
                            autocomplete="off"
                            id="course"
                            type="text"
                            name="course"
                            class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="Password"
                          />
                          <ValidationError
                            prefix="Course"
                            field="course"
                            errors={state.errors}
                          />
                          <label
                            for="password"
                            class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                          >
                            Course Interested
                          </label>
                        </div>

                        <div class="relative">
                          <input
                            required
                            autocomplete="off"
                            id="message"
                            type="text"
                            name="message"
                            class="peer placeholder-transparent h-10 w-full border-b-2 border-gray-300 text-gray-900 focus:outline-none focus:borer-rose-600"
                            placeholder="Password"
                          />
                          <ValidationError
                            prefix="Message"
                            field="message"
                            errors={state.errors}
                          />

                          <label
                            for="password"
                            class="absolute left-0 -top-3.5 text-gray-600 text-sm peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-440 peer-placeholder-shown:top-2 transition-all peer-focus:-top-3.5 peer-focus:text-gray-600 peer-focus:text-sm"
                          >
                            Message
                          </label>
                        </div>
                        <h1 className="mb-5 text-blue-800 text-sm spinnerGrow">
                          {props.msg}
                        </h1>
                        <div class="relative">
                          <button
                            type="submit"
                            disabled={state.submitting}
                            class="relative box2 border  uppercase font-bold border-gradient-to-r from-blue-300 to-[#c43238]  inline-flex items-center justify-start px-6 py-3 overflow-hidden transition-all bg-white rounded hover:bg-white group"
                          >
                            <span class="w-48 h-48 rounded rotate-[-40deg] bg-gradient-to-r from-blue-300 to-[#c43238] absolute bottom-0 left-0 -translate-x-full ease-out duration-500 transition-all translate-y-full mb-9 ml-9 group-hover:ml-0 group-hover:mb-32 group-hover:translate-x-0"></span>
                            <span class="relative w-full text-left text-black transition-colors duration-300 ease-in-out group-hover:text-white">
                              Send Message
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
